<template>
  <Layout tituloPagina="Reporte de clientes por sitio">
    <div class="row">
      <div class="col-lg-4">
        <cmp-secciones seccion="clientesxsitio"></cmp-secciones>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarClientes()
          "
          :datoini="dato"
          placeholder="Dato del cliente"
        ></filtrador>
      </div>
      <div class="col-md-2 mb-3">
        <div class="input-group">
          <span class="input-group-text">
            Sitio
          </span>
          <select
            class="form-select"
            v-model="id_sitio"
            @change="refrescarClientes()"
          >
            <option :value="null">Todos</option>
            <option :value="-1">Sin sitio</option>
            <option v-for="sitio in sitios" :key="sitio.id" :value="sitio.id">
              {{ sitio.nombre }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <div class="input-group">
          <span class="input-group-text">
            Planes de servicio
          </span>
          <select
            class="form-select"
            v-model="id_plan"
            @change="refrescarClientes()"
          >
            <option :value="null">Todos</option>
            <option
              v-for="plan in planes"
              :key="plan.id"
              :value="plan.id"
              :title="plan.descripcion"
              >{{ plan.nombre }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-md-2 mb-2">
        <div class="form-check m-2">
          <input
            class="form-check-input form-check-input-success"
            type="checkbox" id="conIva"
            @click="checkedConIva()" :checked="con_iva"
          />
          <label class="form-check-label" :class="{'text-success': con_iva }" for="conIva">
            <strong>Con impuesto</strong>
          </label>
        </div>
      </div>
      <div class="col-md-2">
        <div class="p-2 rounded text-center bg-info text-white">
          <span style="font-size:16px">
            <b>Total de clientes: {{total_clientes}}</b>
          </span>
        </div>
      </div>
    </div>

    <div class="row" style="padding-top: 10px">
      <div class="col-md-9">
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="sitio"
            v-model="columnas.sitio"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="sitio">Sitio</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="contrato"
            v-model="columnas.contrato"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="contrato">Contrato</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="prepago"
            v-model="columnas.prepago"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="prepago">Modalidad de servicio</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="tipoRenta"
            v-model="columnas.tipo_renta"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="tipoRenta">Tipo de renta</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="cpe"
            v-model="columnas.cpe"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="cpe">CPE</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="planContratado"
            v-model="columnas.plan_contratado"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="planContratado">Plan del contrato</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="precio"
            v-model="columnas.precio"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="precio">Precio del plan</label>
        </div>
      </div>
    </div>
    <br>

    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table
            class="tblFacturas table table-hover table-nowrap"
            style="min-width: 1200px"
            id="tabla-reportes"
          >
            <thead>
              <tr>
                <th style="width: 50px">No.</th>
                <th>Nombre del cliente</th>
                <th v-show="columnas.sitio">Sitio</th>
                <th v-show="columnas.contrato">Contrato</th>
                <th v-show="columnas.prepago">Modalidad del servicio</th>
                <th v-show="columnas.tipo_renta">Tipo de renta</th>
                <th v-show="columnas.cpe">CPE</th>
                <th v-show="columnas.plan_contratado">Plan del contrato</th>
                <th v-show="columnas.precio">Precio del plan</th>
                <th style="width: 203px">Consumo de ancho de banda</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cliente in clientes" :key="cliente.id">
                <td
                  class="td-seleccionable"
                  @click="verTableroCliente(cliente.id_cliente)"
                >
                  <strong>{{ cliente.numero_cliente }}</strong>
                </td>
                <td
                  class="td-seleccionable"
                  @click="verTableroCliente(cliente.id_cliente)"
                >
                  <strong>{{ cliente.nombre_cliente }}</strong>
                </td>
                <td v-show="columnas.sitio">
                  {{ cliente.nombre_sitio }}
                </td>
                <td
                  v-show="columnas.contrato"
                  class="td-seleccionable-contrato"
                  @click="verContrato(cliente.id_cliente, cliente.id_contrato)"
                >
                  <strong>Contrato {{ cliente.id_contrato }}</strong>
                </td>
                <td v-show="columnas.prepago">
                  <span 
                    class="badge bg-success"
                    v-show="cliente.prepago"
                  >
                    Prepago
                  </span>
                  <span
                    class="badge bg-primary"
                    v-show="!cliente.prepago"
                  >
                    Pospago
                  </span>
                </td>
                <td v-show="columnas.tipo_renta">
                  <span 
                    class="badge bg-info"
                    v-show="cliente.id_tipo_renta == 1"
                    title="Renta Mensual Fija"
                  >
                    RMF
                  </span>
                  <span
                    class="badge bg-warning"
                    v-show="cliente.id_tipo_renta == 2"
                    title="Renta Mensual Variable"
                  >
                    RMV
                  </span>
                </td>
                <td
                  v-show="columnas.cpe"
                  class="td-seleccionable-cpe"
                  @click="verCpe(cliente.id_cpe)"
                >
                  <strong>{{ cliente.nombre_cpe }}</strong>
                </td>
                <td v-show="columnas.plan_contratado">
                  {{ cliente.plan_servicio }}
                </td>
                <td 
                  v-show="columnas.precio"
                  class="text-right"
                  :style="con_iva != true?'color:#3B76E1':'color:#63AD6F'"
                >
                  <b :title="con_iva == true?'Con iva':'Sin iva'">{{ formatoMoneda(cliente.precio) }}</b>
                </td>
                <td class="text-right">
                  <b
                    ><span
                      >{{ cliente.descarga }}
                      {{ cliente.unidad_descarga == 'k' ? 'kb' : 'Mb' }}</span
                    ></b
                  >
                  /
                  <span
                    >{{ cliente.carga }}
                    {{ cliente.unidad_carga == 'k' ? 'kb' : 'Mb' }}</span
                  >
                </td>
              </tr>
              <tr>
                <td 
                  class="text-right"
                  :colspan="columnasTotal"
                >
                  <strong>Total</strong>
                </td>
                <td class="text-right" v-show="columnas.precio">
                  <b :style="con_iva != true?'color:#3B76E1':'color:#63AD6F'" :title="con_iva == true?'Con iva':'Sin iva'">
                    {{ formatoMoneda(total_precio) }}
                  </b>
                </td>
                <td class="text-right">
                  <b>{{ convertidor(total_descarga) }}</b> /
                  {{ convertidor(total_carga) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 text-center">
        <paginador
          :pag="n_pag"
          :totpag="totPag"
          :epp="n_cln_pag"
          v-on:cargar-pagina="cargarPagina"
          v-on:epp-actualizado="
            n_cln_pag = $event,
            refrescarClientes()
          "
        ></paginador>
      </div>
      <div class="col-md-3 text-right">
        <button class="btn btn-info" @click="imprimir()">
          Imprimir
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
/**
 * Listado de clientes por sitios
 */
import Layout from '@/views/layouts/main'
import API from '@/API.js'
import CmpSecciones from '../CmpSecciones.vue'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import SitioSrv from '@/services/SitioSrv.js'
import PlanServicioSrv from '@/services/PlanServicioSrv.js'
import ClienteSrv from '@/services/ClienteSrv.js'

import accounting from 'accounting/accounting.js'
// import 'jquery-number'
export default {
  name: 'ListadoClienteSitio',
  components: {
    Layout,
    CmpSecciones,
    Paginador,
    Filtrador
  },
  data() {
    return {
      API: API,
      baseUrl: window.location.origin,
      token: localStorage.getItem('argusblack.token'),
      facturas: [],
      dato: '',
      n_pag: 1, // Número de página
      n_cln_pag: parseInt(localStorage.getItem('argusblack.listadoClienteSitio.por_pagina') || 10), // Número de clientes por página
      totPag: 0, // Total de páginas
      id_sitio: null,
      id_plan: null,
      sitios: [],
      planes: [],
      clientes: [],
      total_precio: 0,
      total_carga: 0,
      total_descarga: 0,
      con_iva: true,
      total_clientes: 0,
      columnas: {
        sitio: true,
        contrato: true,
        prepago: true,
        tipo_renta: false,
        cpe: true,
        plan_contratado: true,
        precio: true
      }
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    },
    columnasTotal: function() {
      var self = this, total = 8

      if(!self.columnas.sitio) total--
      if(!self.columnas.contrato) total--
      if(!self.columnas.prepago) total--
      if(!self.columnas.tipo_renta) total--
      if(!self.columnas.cpe) total--
      if(!self.columnas.plan_contratado) total--

      return total
    }
  },
  created: function() {
    let self = this

    // Ejecución de instrucciones iniciales
    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.n_pag) this.n_pag = parseInt(this.$route.query.n_pag)
    if (this.$route.query.n_cln_pag) this.n_cln_pag = parseInt(this.$route.query.n_cln_pag)
    if (this.$route.query.con_iva) self.con_iva = this.$route.query.con_iva='true'? true:false
    if (this.$route.query.id_sitio) self.id_sitio = parseInt(self.$route.query.id_sitio)
    if (this.$route.query.id_plan) self.id_plan = parseInt(self.$route.query.id_plan)

    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()

    self.cargarSitios()
    self.cargarPlanes()
    
    self.refrescarClientes()
  },
  methods: {
    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.ListadoClienteSitio.columnas', JSON.stringify(self.columnas))
    },

    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.ListadoClienteSitio.columnas')

      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },

    cargarPagina: function(n) {
      var self = this
      self.n_pag = n
      self.refrescarClientes({ n_pag: n })
    },

    cargarSitios: function() {
      var self = this

      SitioSrv.sitiosJSON().then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlanes: function() {
      var self = this

      let params = {
        paginacion: false
      }

      PlanServicioSrv.planesJSON(params).then(response => {
        let res = response.data
        self.planes = res
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los planes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    checkedConIva: function() {
      var self = this

      if(self.con_iva != true) self.con_iva = true
      else self.con_iva = false
      if (self.con_iva == true) {
        document.getElementById("conIva").checked = true
      }
      else {
        document.getElementById("conIva").checked = false
      }
      
      self.refrescarClientes()
    },

    convertidor(kb) {
      let valor = ''
      if (kb <= 1024) {
        valor = kb.toFixed(2) + ' kb'
      }
      if (kb > 1024 && kb <= 1048576) {
        let mb = kb / 1024
        valor = mb.toFixed(2) + ' Mb'
      }
      if (kb > 1048576 && kb <= 1073741824) {
        let gb = kb / 1048576
        valor = gb.toFixed(2) + ' Gb'
      }
      return valor
    },

    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },

    imprimir() {
      var self = this
      let url = `${API}/reporte-clientes-sitios/impresion?`,
        query = ''

      Object.params = {}

      self.params = Object.assign({
        pagina: self.n_pag,
        por_pagina: self.n_cln_pag,
        dato: self.dato,
        activos: true,
        retirados: false,
        todos: false,
        id_sitio: self.id_sitio,
        id_plan: self.id_plan,
        con_iva: self.con_iva,
        con_total_clientes_sitio: true
      })

      // Carga del query
      Object.keys(this.params).forEach(key => {
        let value = this.params[key]
        if (value == null) return // Si es null, evita agregarlo al query
        query += `${key}=${value}&`
      })

      url += query + "_tk="+this.token

      window.open(url, '_blank')
    },

    refrescarClientes() {
      var self = this

      iu.spinner.mostrar('#tabla-reportes')

      this.$router.replace({
        query: {
          dato: self.dato,
          n_pag: self.n_pag,
          n_cln_pag: self.n_cln_pag,
          id_sitio: self.id_sitio,
          id_plan: self.id_plan,
          con_iva: self.con_iva
        }
      }).catch(error => {})

      let params = {
        pagina: self.n_pag,
        por_pagina: self.n_cln_pag,
        dato: self.dato,
        activos: true,
        retirados: false,
        todos: false,
        id_sitio: self.id_sitio,
        id_plan: self.id_plan,
        con_iva: self.con_iva,
        con_total_clientes_sitio: true
      }

      ClienteSrv.clientesSitioJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoClienteSitio.por_pagina', self.n_cln_pag)
        let res = response.data
        self.clientes = res.clientes
        self.tc = res.total
        self.totPag = res.ultima_pagina
        self.total_precio = res.total_precio
        self.total_carga = res.total_carga
        self.total_descarga = res.total_descarga
        self.total_clientes = res.total_clientes

        if (self.totPag < self.n_pag) {
          self.n_pag = self.totPag
          self.refrescarClientes()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo refrescar los clientes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-reportes')
      })
    },

    verTableroCliente: function(id) {
      var self = this

      self.$router.push({ name: 'tableroCliente', params: { id_cliente: id } })
    },

    verContrato: function(id_cliente, id_contrato) {
      var self = this

      self.$router.push({
        name: 'edicionContrato',
        params: { id_cliente: id_cliente, id_contrato: id_contrato }
      })
    },

    verCpe: function(id) {
      var self = this

      self.$router.push({ name: 'edicionCpe', params: { id: id } })
    }
  },
  mounted: function () {
    iu.spinner.mostrar('#tabla-reportes')
  }
}
</script>

<style scoped>
.td-seleccionable {
  cursor: pointer;
}
.td-seleccionable:hover {
  color: #63AD6F;
}
.td-seleccionable-cpe {
  cursor: pointer;
}
.td-seleccionable-cpe:hover {
  color: #5ac0de;
}
.td-seleccionable-contrato {
  cursor: pointer;
}
.td-seleccionable-contrato:hover {
  color: #3B76E1;
}
.form-check-input-success:checked {
  background-color: #63AD6F;
  color: #63AD6F;
  border-color: #63AD6F;
}
</style>